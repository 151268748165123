<template>
  <div style="margin: -15px -15px">
    <a-spin :spinning="spinning">
    <div class="case">

      <!--    身份选择-->
      <div class="type-left">
        <a-menu
            style="width: 230px"
            :defaultSelectedKeys="selectId"
            mode="inline"
        >
          <a-menu-item
              v-for="(item,index) in templateList"
              @click="SelectIdentity(item.id,item)"
              :key="item.id">
            {{item.title}}
          </a-menu-item>
        </a-menu>
      </div>

      <!-------------------------------   内容列表区    --------------------------------------------->
      <div class="case-right">
        <!--      下拉选择选择状态-->
        <div class="case-right-select">
          <div>
            <span>状态: </span>
            <a-select
                v-model="selectCtatus"
                placeholder="请选择状态"
                style="width: 120px;"
                @change="selectStatus">
              <a-select-option value="0">
                待打码
              </a-select-option>
              <a-select-option value="1">
                已打码
              </a-select-option>
            </a-select>
          </div>
          <div style="margin-left: 20px">
            <span style="height: 35px;line-height: 30px">上传时间: </span>
            <a-range-picker
                v-model="sum_time"
                :allowClear="false"
                @change="startChange"
                style="width: 200px" />
          </div>
          <div style="margin-left: 10px">
            <a-button
                @click="searchBtn"
                type="primary">查询</a-button>
            <a-button
                style="margin-left: 5px"
                @click="returnBtn"
            >重置</a-button>
          </div>
          <div class="aleart">
            <a-icon style="background-color: #1890ff;height: 14px;width: 14px;border-radius: 14px;color: #e8e8e8" type="exclamation-circle" />
            待打码：{{numberData.toBeCount}}  已打码：{{numberData.alreadyCount}}
<!--            待打码：1  已打码：1-->

          </div>
        </div>
        <a-table
            :columns="columns"
            :data-source="caseList"
            :pagination="false"
            style="height: auto"
            :rowKey="record=>record.id">
           <span slot="system" slot-scope="text, record">
             <a @click="codeBtn(record.id)">打码</a>
           </span>
          <span slot="memberName" slot-scope="text, record">
             {{record.memberName }} -{{ record.memberHospitalName}}
           </span>
<!--          <span slot="patientName" slot-scope="text, record">-->
<!--             {{record.patientSex }}/{{record.patientSex === 1 ? '男':'女' }}/{{ record.patientAge}}岁-->
<!--           </span>-->
          <span slot="imageAuditStatus" slot-scope="text, record">
<!--             {{record.imageAuditStatus === '0' ? '待打码' : '已打码'}}-->
            <div v-if="record.imageAuditStatus === 0">
                  <div style="height: 30px;line-height: 30px;display: flex;">
                  <div class="div_garden"></div>
                  <div >待打码</div>
                  </div>
                </div>
            <div v-if="record.imageAuditStatus === 1">
                  <div style="height: 30px;line-height: 30px;display: flex;">
                  <div class="div_garden_adopt"></div>
                  <div >已打码</div>
                  </div>
                </div>
           </span>
        </a-table>
        <div style="width: 100%;height: 50px;">
          <div style="float: right;margin-right: 20px;margin-top: 15px">
            <a-pagination
                :current="page_no"
                show-quick-jumper
                @change="selectPage"
                :total="count" />
          </div>
        </div>
      </div>
    </div>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import {getmoBanList, getNumberList, getPicturesCode} from "../../../service/case_api";

export default {
  data(){
    return{
      sum_time:[],
      numberData:{
        toBeCount:"",
        alreadyCount:"",
      },
      spinning:false,
      count:50,
      selectId:[0],
      templateList:[],
      selectCtatus:undefined,
      start_time:'',
      end_time:'',
      page_no:null,
      page_size:'',
      columns:[
        {
          title: '病例 ID',
          dataIndex: 'id',
          align: 'center',
          width:100,
          scopedSlots: { customRender: 'id' },
        },
        {
          title: '医生信息',
          dataIndex: 'memberName',
          align: 'center',
          scopedSlots: { customRender: 'memberName' },
        },
        // {
        //   title: '患者信息',
        //   dataIndex: "patientName",
        //   align: 'center',
        //   ellipsis: true,
        //   width:100,
        //   scopedSlots: { customRender: 'patientName' },
        // },
        {
          title: '上传时间',
          dataIndex: 'created_time',
          align: 'center',
          scopedSlots: { customRender: 'created_time' },
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'imageAuditStatus',
          align: 'center',
          width:100,
          scopedSlots: { customRender: 'imageAuditStatus' },
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'system',
          ellipsis: true,
          width:100,
          scopedSlots: { customRender: 'system' },
        },
      ],
      caseList:[],
    }
  },
  async created() {
     await this.getTemplate()
     await this.getTableList()
     await this.getNumber()
  },
  computed:{
    //左侧历史id
    returnLeftId(){
      return this.$store.state.returnLeftId
    },
    //历史状态
    picState(){
      return this.$store.state.picState
    },
  },
  methods:{
    //获取模板列表
    async getTemplate() {
      // const is_audit = true
      const is_sh_pic = 1
      let data = {
        isShPic:is_sh_pic
      }
      const response = await getmoBanList(data)
      if(response.code === 0){
        this.templateList = response.data.rows
        if(this.returnLeftId === ''){
          //初始选中第一条
          this.selectId[0] = response.data.rows[0].id
        }else {
          this.selectId[0] = this.returnLeftId
        }
      }else {
        this.$message.warning("失败，请联系管理员"+response.message)
      }
    },
    //获取列表
    async getTableList(page_no,page_size,imageAuditStatus,start_time,end_time) {
      this.spinning = true
      let template_id = this.selectId[0]
      if(this.picState !== ''){
        imageAuditStatus = this.picState
        this.selectCtatus = this.picState
      }
      const response = await getPicturesCode(page_no,page_size,template_id,imageAuditStatus,start_time,end_time)
      if (response.code === 0) {
        this.caseList = response.data.rows
        this.count = response.data.count
      } else {
        this.$message.warning("失败，请联系管理员" + response.message)
      }
      this.spinning = false
    },
    //打码统计
    async getNumber(start_time,end_time) {
      let template_id = this.selectId[0]
      let type = 'isShPic'
      const response = await getNumberList(template_id,type,start_time,end_time)
      if (response.code === 0) {
        this.numberData = response.data
      } else {
        this.$message.warning("失败，请联系管理员" + response.message)
      }
    },
    SelectIdentity(id,row){
      //显示第一页
      this.page_no = 1
      this.$store.commit('returnLeftId',id)
      this.$store.commit('picState','')
      this.selectId[0] = id
      this.getTableList(this.page_no,this.page_size,'',this.start_time,this.end_time)
      this.getNumber()
      this.selectCtatus = undefined
      this.start_time = ''
      this.end_time = ''
      this.sum_time = []
    },
    selectStatus(val){
      this.$store.commit('picState',this.selectCtatus)
      let status;
      if(this.picState === ''){
        status = this.selectCtatus
      }else {
        status = this.picState
      }
      this.getTableList(this.page_no,this.page_size,status,this.start_time,this.end_time)
    },
    startChange(date,dateString){
      this.start_time = dateString[0]
      this.end_time = dateString[1]
    },
    searchBtn(){
      this.getTableList(this.page_no,this.page_size,this.selectCtatus,this.start_time,this.end_time)
      this.getNumber(this.start_time,this.end_time)
    },
    returnBtn(){
      this.selectCtatus = undefined
      this.start_time = ''
      this.end_time = ''
      this.sum_time = []
    },
    selectPage(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      this.getTableList(page_no,page_size,this.selectCtatus,this.start_time,this.end_time)
    },
    codeBtn(id){
      const user = JSON.parse(window.localStorage.getItem("user_info"))
      let userId = user.id
      let userName = user.user_name
      console.log(user)
      this.$router.push({path:"caseCode",query:{id:id,userId:userId,userName:userName}})
    }
  }
}
</script>


<style lang="scss" scoped>
.caseTitle{
  font-size: 22px;
  font-weight: bold;
  color: black;
  height:100px;
  line-height: 80px;
  padding-left: 30px;
  border-bottom: 15px solid #f5f5f5;
}
.case{
  width: 100%;
  height: auto;
  display: flex;
  .type-left{
    .type-left-select{
      width: 220px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
    }
  }
  .case-right{
    padding: 15px;
    //height: auto;
    min-height: 700px;
    border-left: 15px solid #f5f5f5;
    .case-right-select{
      width: 100%;
      //margin-left: 85%;
      margin-right: 15%;
      padding-bottom: 15px;
      display: flex;
      .aleart{
        padding-left: 10px;
        margin-left: 20px;
        height: 32px;
        line-height: 32px;
        background-color: #eafbff;
        min-width: 200px;
        border-radius: 5px;
        border: 1px solid #3a9af3;
      }
    }
    .paegSty{
      width: 100%;
      margin-top: 15px;
    }
  }
}
.div_garden{
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-top: 12px;
  margin-right: 8px;
  background-color: #45A5E6;
}
.div_garden_adopt{
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-top: 12px;
  margin-right: 8px;
  background-color: #45e648;
}
</style>
